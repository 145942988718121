<script setup>
    import { defineProps, onMounted, nextTick, ref } from 'vue';
    import { helpers } from '../../../helpers';
    import Modals from './Modals.vue';
    import { useWebSocketStore } from '../../stores/WebSocket';

    const globalStore = window.globalStore;
    const socketStore = useWebSocketStore();

    let props = defineProps(['showFooter', 'online']);

    const online = ref(props.online);

    setInterval( () => {

        $.post("/online/check", {},
            function (data, textStatus, jqXHR) {

                if(data.online != null) {
                    online.value = data.online;
                }
            },
        );
    }, 10000);

/*     // Connect to websocket
    onMounted( () => {
        let token = globalStore.user == null ? 'demo' : globalStore.user.token
        socketStore.connect(token);
    } ); */

</script>

<template>
    <footer class="footer" v-if="showFooter !== false">
        <div class="footer__content">
            <div class="footer__info">
                <div class="footer__info-line">
                    <!-- <img src="../../../../images/logo.svg" alt="" class="img-adaptive"> -->
                    <img :src="globalStore.logo" alt="" class="img-adaptive">
                    <div class="online">
                        Online
                        <div class="online__amount">
                            <div class="online__status"></div>
<!--                             <transition name="fade" mode="out-in">
                                <span :key="online">{{ online }}</span>
                            </transition> -->
                            <span :key="online">{{ online }}</span>
                        </div>
                    </div>
                </div>

                <div class="footer__info-text">
                    CSGOHunt is operated by CSGOHunt Entertainment, Sorsaka W-2, Curaçao (Company Registration No. 118350).<br>
                    A company licensed and regulated by the laws of Curaçao under license number 9253/JAZ.
                </div>

                <div class="copyright">Copyright © 2024 csgohunt.gg. All rights reserved.</div>
            </div>

            <div class="footer__links">
                <div class="footer__links-column">
                    <p>Quick access</p>
                    <ul class="ul-clear">
                        <li>
                            <a href="/daily_race">Daily race</a>
                        </li>
                        <li>
                            <a href="#" @click="helpers.openPopup('#modal-partnership')">Partnership</a>
                        </li>
                        <li>
                            <a href="/shop">Skin Shop</a>
                        </li>
                        <li>
                            <a href="#" @click="helpers.openPopup('#modal-deposit')">Deposit</a>
                        </li>
                    </ul>
                </div>

                <div class="footer__links-column">
                    <p>About</p>
                    <ul class="ul-clear">
                        <li>
                            <a href="/policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms">Terms of Services</a>
                        </li>
                        <li>
                            <a href="/faq">FAQ</a>
                        </li>
                    </ul>
                </div>

                <div class="payment-types">
                    <div class="payment-type">
                        <img src="../../../../images/icon-btc.svg" alt="" class="img-adaptive">
                    </div>
                    <div class="payment-type">
                        <img src="../../../../images/icon-eth.svg" alt="" class="img-adaptive">
                    </div>
                    <div class="payment-type">
                        <img src="../../../../images/icon-ltc.svg" alt="" class="img-adaptive">
                    </div>
                    <div class="payment-type">
                        <img src="../../../../images/icon-usdt.svg" alt="" class="img-adaptive">
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <Modals></Modals>
</template>
